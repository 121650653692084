<template>
  <div class="explore-course-card-component" v-if="coursesData">
    <b-row
      v-if="coursesData.length"
      class="justify-content-center justify-content-md-start"
    >
      <overlay-component :isLoading="is_loading" />
      <b-col cols="auto mb-2" v-for="course in coursesData" :key="course.id">
        <b-card
          :img-src="
            course.image && course.image.length
              ? course.image[0].path
              : courseImagePlaceholder
          "
          :img-alt="course.name"
          img-top
          tag="article"
          class=""
          body-class="d-flex flex-column justify-content-between"
        >
          <b-card-title class="card-title" style="margin-bottom: 8px">
            <p
              class="mb-0"
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              "
            >
              {{ course.name }}
            </p>
          </b-card-title>
          <b-card-text id="text" style="margin-bottom: 8px">
            <div class="d-flex" v-if="!!canAddToCart">
              <p
                :class="
                  course.sale  && course.sale !== course.price? 'mr-1 mb-0 course-price' : 'mb-0 course-price'
                "
              >
                {{ `${course.sale && course.sale !== course.price ? course.sale : course.price} ${currency}` }}
              </p>
              <s v-if="course.sale && course.sale !== course.price">{{ `${course.price} ${currency}` }}</s>
            </div>
          </b-card-text>
          <b-card-text class="description-text p-">
            {{
              course.description
                ? course.description.substr(0, 60)
                : $t("g.ThereIsNoDescription")
            }}
          </b-card-text>
          <b-card-footer class="d-flex p-0">
            <b-button
              v-if="!!canAddToCart"
              :disabled="is_loading"
              variant="#135991"
              id="button"
              @click="addToCart(course.id)"
            >
              {{ $t("g.explore_add_to_cart") }}
            </b-button>
            <b-link
              :to="
                isPackage
                  ? `/class-rooms/package/course/${course.id}`
                  : `/explore/course/${course.id}`
              "
            >
              <b-button variant="outline-primary" class="button-link">
                {{ $t("g.explore_course_view") }}
              </b-button>
            </b-link>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <h2 v-else class="no-data-msg">
      {{ $t("g.there_is_no_courses_at_the_time") }}
    </h2>
  </div>
</template>

<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BButton,
  BCardText,
  BCardTitle,
  BCardFooter,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BButton,
    BCardText,
    BCardTitle,
    BCardFooter,
    OverlayComponent,
  },
  props: {
    canAddToCart: {
      default: true,
    },
    isPackage: {
      default: false,
    },
    coursesData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      is_loading: false,
      courseImagePlaceholder: require("@/assets/dev/courseImagePlaceholder.png"),
    };
  },
  computed: {
    currency() {
      return this.$store.state?.userData?.userData?.country?.currency;
    },
  },
  methods: {
    async addToCart(courseId) {
      try {
        this.is_loading = true;
        const formData = new FormData();
        formData.append("course_id", courseId);
        formData.append("qty", 1);
        await this.$http.post("/guardian/cart", formData);
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("g.course_added_to_cart_successfully")
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_ExploreCoursesCourseCardComponent.scss";
</style>
